exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-achievements-js": () => import("./../../../src/pages/achievements.js" /* webpackChunkName: "component---src-pages-achievements-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-people-js": () => import("./../../../src/pages/en/people.js" /* webpackChunkName: "component---src-pages-en-people-js" */),
  "component---src-pages-en-publications-js": () => import("./../../../src/pages/en/publications.js" /* webpackChunkName: "component---src-pages-en-publications-js" */),
  "component---src-pages-en-research-js": () => import("./../../../src/pages/en/research.js" /* webpackChunkName: "component---src-pages-en-research-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-templates-people-template-js": () => import("./../../../src/templates/people-template.js" /* webpackChunkName: "component---src-templates-people-template-js" */)
}

